import { useLoginMutation } from '@/lib/api/baseApi'
import { useAppDispatch, } from '@/lib/hooks'
import { setLogout, setUser } from '@/lib/slice/authSlice'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import React from 'react'
import { useAccount } from 'wagmi'

export default function ConnectButton() {
    const { isDisconnected, isConnected, address } = useAccount()
    const { open } = useWeb3Modal()
    const [login, { data, isSuccess }] = useLoginMutation();

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (isConnected) {
            // successAlert("isConnected", 'top-center')
            login({
                "walletAddress": address,
                "blockchain": "EVM"
            })
        } else {
            dispatch(setLogout())
            //! handle the logout state here when wallet is disconnected
        }
    }, [isConnected])

    React.useEffect(() => {
        if (isSuccess) {
            dispatch(setUser({
                token: data?.token,
                auth: true,
                wallet: address,
                role: data?.role,
                profile: data?.user
            }))
        }
    }, [isSuccess])
    return (
        <>
            <button variant='contained' onClick={() => open()} className='btn-default btn-small round'>
                {isConnected ? "Disconnect" : "Connect"}
            </button>
        </>
    )
}