import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import { useAppContext } from "@/context/Context";
import ConnectButton from "../wallet/WagmiWallet";
import { Avatar } from "@mui/material";

const Header = ({ headerTransparent, headerSticky, btnClass }) => {
  const { activeMobileMenu, setActiveMobileMenu } = useAppContext();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header
        className={`rainbow-header header-default ${headerTransparent} ${headerSticky} ${isSticky ? "sticky" : ""
          }`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-6">
              <div className="logo d-flex align-items-center justify-content-center gap-2 ">
                <Link href="/">
                  <Avatar
                    // className="logo-light"
                    src='/aptron-logo.png'
                    // width={200}
                    // height={100}
                    alt="ChatBot Logo"
                    sx={{ width: '180px', borderRadius: '0' }}
                  />
                  {/* <h3 className="mb-0">APTRON</h3> */}
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-6 position-static">
              <div className="header-right">
                {/* <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav> */}
                <ConnectButton />
                {/* <MetamaskWalletConnect /> */}
                {/* <div className="header-btn">
                  <Link
                    className={`btn-default ${btnClass}`}
                    href="/ai-chatbot"
                  >
                    Get Started Free
                  </Link>
                </div> */}

                {/* <GridMenu ToolsData={ToolsData} /> */}

                {/* <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <button
                      className="hamberger-button"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <i className="feather-menu"></i>
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header >
    </>
  );
};

export default Header;
